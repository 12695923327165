import axios from 'axios';
import { getAuth } from "firebase/auth";

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://localytics-platform-server-816445c97ee2.herokuapp.com/';
axios.defaults.baseURL = baseURL;

export async function getReviews() {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      console.error('No authenticated user found');
      throw new Error('Authentication required');
    }

    console.log('Making request with user:', user.email);
    
    const response = await axios.get('/reviews', {
      params: {
        user: user.email
      },
      headers: {
        'Authorization': `Bearer ${await user.getIdToken()}`
      }
    });

    console.log('Response received:', response.data);

    if (!response.data) {
      throw new Error('No data received from server');
    }

    return response.data;
  } catch (error) {
    console.error("Error in getReviews:", error);
    if (error.response) {
      console.error("Server response:", error.response.data);
      throw new Error(error.response.data.message || 'Server error');
    }
    throw error;
  }
}

export default getReviews;