import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from '@chakra-ui/react';
import WeekReviewParentComponentV2 from './WeekReviewParentComponentV2';
import YearReviewParentComponent from './YearReviewParentComponent';

const BusinessReviewModal = ({ isOpen, onClose, business, yearData }) => {
  if (!business) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent maxW="90vw" maxH="90vh" mx="auto" my="5vh">
        <ModalHeader>{business.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs defaultIndex={1}>
            <TabList>
              <Tab>Week in Review</Tab>
              <Tab>Year in Review</Tab>
            </TabList>

            <TabPanels>
              {/* Week in Review Panel */}
              <TabPanel>
                <Box overflowY="auto" maxH="70vh">
                  <WeekReviewParentComponentV2 
                    selectedBusiness={business}
                    isModal={true}
                  />
                </Box>
              </TabPanel>

              {/* Year in Review Panel */}
              <TabPanel>
                <Box overflowY="auto" maxH="70vh">
                  <YearReviewParentComponent 
                    selectedBusiness={business}
                    isModal={true}
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BusinessReviewModal; 