import React from 'react';
import { Box, VStack, HStack, Text, Badge, Progress } from '@chakra-ui/react';

const RankingListComponent = ({ side, title, data, valueKey, unit }) => {
  const maxValue = Math.max(...data.map(item => item[valueKey]));

  return (
    <Box height="100%">
      <Text fontSize="md" fontWeight="semibold" mb={6}>{title}</Text>
      <VStack spacing={6} align="stretch" height="calc(100% - 60px)" justify="space-between">
        {data.map((item, index) => {
          const value = item[valueKey];
          const percentage = (value / maxValue) * 100;
          const color = side === 'top' ? 'blue' : 'red';
          
          return (
            <Box key={item.name} position="relative">
              <HStack spacing={3} mb={2}>
                <Badge 
                  colorScheme={color} 
                  borderRadius="full" 
                  minW="24px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="sm"
                >
                  {side === 'top' ? index + 1 : data.length - index}
                </Badge>
                <Text fontSize="md" fontWeight="medium" noOfLines={1} flex={1}>
                  {item.name}
                </Text>
                <Text fontSize="md" fontWeight="bold" whiteSpace="nowrap" ml={2}>
                  {typeof value === 'number' ? value.toLocaleString() : value}{unit}
                </Text>
              </HStack>
              <Progress 
                value={percentage} 
                size="sm" 
                colorScheme={color}
                borderRadius="full"
                bg={`${color}.50`}
              />
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export default RankingListComponent;