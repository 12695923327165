import axios from 'axios';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://localytics-platform-server-816445c97ee2.herokuapp.com/';
axios.defaults.baseURL = baseURL;

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export async function getWeekData() {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      throw new Error('No authenticated user');
    }

    const response = await axios.get('/week-in-review', {
      params: {
        user: currentUser.email
      }
    });

    // console.log('getReviews response', response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}

export default getWeekData;