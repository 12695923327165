import React, { useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Cell,
  ReferenceLine,
  PieChart,
  Pie,
} from 'recharts';
import RankingListComponent from '../RankingListComponent';
import DataTable from 'react-data-table-component';
import BusinessReviewModal from '../BusinessReviewModal';
import getYearData from '../../services/yearService';

const RelativeGrowthDashboard = ({ reviewsData }) => {
  const [yearData, setYearData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearDataResponse = await getYearData();
        setYearData(yearDataResponse);
        
        // Combine data using maps_uri as unique identifier
        const combined = reviewsData.map(currentData => {
          const historicalData = yearDataResponse.find(yd => 
            yd.maps_uri === currentData.maps_uri
          );
          
          return {
            ...currentData,
            historicalData: historicalData?.data || null
          };
        });
        
        setCombinedData(combined);
      } catch (error) {
        console.error("Error fetching year data:", error);
      }
    };

    if (reviewsData?.length > 0) {
      fetchData();
    }
  }, [reviewsData]);

  // Calculate relative growth metrics for each business
  const businessGrowthData = combinedData.map(business => {
    const monthlyData = business.historicalData?.monthlyReviewCounts || {};
    const months = Object.keys(monthlyData).sort();
    
    // Skip if not enough data
    if (months.length < 3) {
      return {
        ...business,
        threeMonthGrowth: 0,
        relativeGrowth: 0,
        growthRate: 0,
        totalReviews: business.rating_count
      };
    }

    // Get last 3 months of data
    const lastThreeMonths = months.slice(-3);
    const threeMonthGrowth = lastThreeMonths.reduce((sum, month) => sum + monthlyData[month], 0);
    
    // Calculate relative growth (as percentage of total reviews)
    const baseReviews = business.rating_count - threeMonthGrowth; // Total reviews before growth
    const relativeGrowth = baseReviews > 0 ? (threeMonthGrowth / baseReviews) * 100 : 0;
    
    // Calculate monthly growth rate
    const growthRate = threeMonthGrowth / 3;
    
    return {
      ...business,
      threeMonthGrowth,
      relativeGrowth,
      growthRate,
      totalReviews: business.rating_count
    };
  });

  // Sort by relative growth
  const sortedByGrowth = [...businessGrowthData]
    .sort((a, b) => b.relativeGrowth - a.relativeGrowth);

  const top5Growth = sortedByGrowth.slice(0, 5);
  const bottom5Growth = sortedByGrowth.slice(-5).reverse();

  // Calculate market-wide metrics
  const avgRelativeGrowth = sortedByGrowth.length > 0 ?
    sortedByGrowth.reduce((sum, b) => sum + (b?.relativeGrowth || 0), 0) / sortedByGrowth.length : 0;
  const medianRelativeGrowth = sortedByGrowth.length > 0 ? 
    sortedByGrowth[Math.floor(sortedByGrowth.length / 2)]?.relativeGrowth || 0 : 0;
  const avgGrowthRate = sortedByGrowth.length > 0 ?
    sortedByGrowth.reduce((sum, b) => sum + (b?.growthRate || 0), 0) / sortedByGrowth.length : 0;

  // Colors for charts
  const COLORS = [
    '#3182CE', // blue.500
    '#4299E1', // blue.400
    '#63B3ED', // blue.300
    '#90CDF4', // blue.200
    '#BEE3F8'  // blue.100
  ];

  // Prepare data for bar chart
  const barChartData = top5Growth
    .filter(business => business && business.relativeGrowth !== undefined)
    .map((business, index) => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      'Relative Growth': business.relativeGrowth,
      'Market Average': avgRelativeGrowth,
      fill: COLORS[index % COLORS.length]
    }));

  // Prepare data for pie chart
  const pieChartData = top5Growth
    .filter(business => business && business.relativeGrowth !== undefined)
    .map((business, index) => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      value: business.relativeGrowth,
      fill: COLORS[index % COLORS.length]
    }));

  // Prepare monthly trend data for top 5 businesses
  const monthlyTrendData = months => {
    const lastSixMonths = months.slice(-6);
    return lastSixMonths.map(month => {
      const monthData = {
        month: month.split('-')[1], // Get just the month part
      };
      
      // Calculate market average for this month
      let totalGrowth = 0;
      let totalBaseReviews = 0;
      sortedByGrowth.forEach(business => {
        const monthlyData = business.historicalData?.monthlyReviewCounts || {};
        const monthlyGrowth = monthlyData[month] || 0;
        const baseReviews = business.rating_count - 
          Object.values(monthlyData).reduce((sum, count) => sum + count, 0);
        totalGrowth += monthlyGrowth;
        totalBaseReviews += baseReviews;
      });
      monthData['Market Average'] = totalBaseReviews > 0 ? (totalGrowth / totalBaseReviews) * 100 : 0;
      
      // Add data for top 5 businesses
      top5Growth.forEach(business => {
        const monthlyData = business.historicalData?.monthlyReviewCounts || {};
        const baseReviews = business.rating_count - 
          Object.values(monthlyData).reduce((sum, count) => sum + count, 0);
        const monthlyGrowth = monthlyData[month] || 0;
        monthData[business.name.split(' ').slice(0, 2).join(' ')] = 
          baseReviews > 0 ? (monthlyGrowth / baseReviews) * 100 : 0;
      });
      
      return monthData;
    });
  };

  // Prepare data table columns
  const columns = [
    {
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Relative Growth',
      selector: row => row.relativeGrowth,
      sortable: true,
      right: true,
      format: row => `${row.relativeGrowth.toFixed(1)}%`,
    },
    {
      name: 'Monthly Growth Rate',
      selector: row => row.growthRate,
      sortable: true,
      right: true,
      format: row => row.growthRate.toFixed(1),
    },
    {
      name: 'Total Reviews',
      selector: row => row.totalReviews,
      sortable: true,
      right: true,
      format: row => row.totalReviews.toLocaleString(),
    }
  ];

  const handleBusinessClick = (business) => {
    setSelectedBusiness(business);
    setIsModalOpen(true);
  };

  // Add click handlers to various components
  const customBarProps = {
    onClick: (data) => {
      const business = sortedByGrowth.find(b => 
        b.name.split(' ').slice(0, 2).join(' ') === data.payload.name
      );
      if (business) handleBusinessClick(business);
    },
    style: { cursor: 'pointer' }
  };

  // Modify the data table to handle row clicks
  const handleRowClick = (row) => {
    handleBusinessClick(row);
  };

  return (
    <Box>
      <Heading size="lg" mb={6}>Relative Growth Leaderboard</Heading>

      {/* Top 3 Podium */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        {/* Silver - Second Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="sm" textAlign="center" 
          position="relative" top={{ base: 0, md: "20px" }}
          cursor="pointer"
          onClick={() => top5Growth[1] && handleBusinessClick(top5Growth[1])}
        >
          <Text fontSize="xl" mb={2}>🥈</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Growth[1]?.name}</Heading>
          <Text fontWeight="bold" fontSize="xl" color="gray.600">
            {top5Growth[1]?.relativeGrowth.toFixed(1)}%
          </Text>
          <Text fontSize="sm" color="gray.500">growth rate</Text>
        </Box>
        {/* Gold - First Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="lg" textAlign="center"
          transform="scale(1.05)" zIndex={1}
          cursor="pointer"
          onClick={() => top5Growth[0] && handleBusinessClick(top5Growth[0])}
        >
          <Text fontSize="2xl" mb={2}>🏆</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Growth[0]?.name}</Heading>
          <Text fontWeight="bold" fontSize="2xl" color="gray.700">
            {top5Growth[0]?.relativeGrowth.toFixed(1)}%
          </Text>
          <Text fontSize="sm" color="gray.500">growth rate</Text>
        </Box>
        {/* Bronze - Third Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="sm" textAlign="center"
          position="relative" top={{ base: 0, md: "20px" }}
          cursor="pointer"
          onClick={() => top5Growth[2] && handleBusinessClick(top5Growth[2])}
        >
          <Text fontSize="xl" mb={2}>🥉</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Growth[2]?.name}</Heading>
          <Text fontWeight="bold" fontSize="xl" color="gray.600">
            {top5Growth[2]?.relativeGrowth.toFixed(1)}%
          </Text>
          <Text fontSize="sm" color="gray.500">growth rate</Text>
        </Box>
      </SimpleGrid>

      {/* Market Overview */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Average Growth Rate</StatLabel>
          <StatNumber>{avgRelativeGrowth.toFixed(1)}%</StatNumber>
          <StatHelpText>Of existing reviews</StatHelpText>
        </Stat>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Median Growth Rate</StatLabel>
          <StatNumber>{medianRelativeGrowth.toFixed(1)}%</StatNumber>
          <StatHelpText>Market middle point</StatHelpText>
        </Stat>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Average Monthly Growth</StatLabel>
          <StatNumber>{avgGrowthRate.toFixed(1)}</StatNumber>
          <StatHelpText>Reviews per month</StatHelpText>
        </Stat>
      </SimpleGrid>

      {/* Main Content Area with Tabs */}
      <Box bg="white" borderRadius="lg" boxShadow="sm" mb={8}>
        <Tabs>
          <TabList px={4} pt={4}>
            <Tab>Overview</Tab>
            <Tab>Trends</Tab>
            <Tab>Rankings</Tab>
            <Tab>Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Growth Distribution Bar Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Relative Growth Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={sortedByGrowth.slice(0, 20).map(business => ({
                        name: business.name.split(' ').slice(0, 2).join(' '),
                        growth: business.relativeGrowth
                      }))}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                      animationDuration={1000}
                      animationBegin={0}
                      animationEasing="ease-out"
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                      />
                      <YAxis unit="%" />
                      <Tooltip 
                        formatter={(value) => `${value.toFixed(1)}%`}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                                <p>{`${payload[0].payload.name}: ${payload[0].value.toFixed(1)}%`}</p>
                                <p style={{ color: '#ED8936' }}>{`Market Average: ${avgRelativeGrowth.toFixed(1)}%`}</p>
                              </div>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="growth" 
                        fill={COLORS[0]} 
                        {...customBarProps}
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={300}
                        animationEasing="ease-out"
                      >
                        {sortedByGrowth.slice(0, 20).map((_, index) => (
                          <Cell key={`cell-${index}`} fill={index < 5 ? COLORS[index] : '#CBD5E0'} />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={avgRelativeGrowth} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Growth Leaders Pie Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Growth Leaders</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={(entry) => `${entry.name} (${entry.value.toFixed(1)}%)`}
                        isAnimationActive={true}
                        animationBegin={0}
                        animationDuration={800}
                        animationEasing="ease-out"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Trends Panel */}
            <TabPanel>
              <Box height="500px">
                <Heading size="md" mb={4}>Monthly Relative Growth Trends</Heading>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={monthlyTrendData(Object.keys(top5Growth[0]?.historicalData?.monthlyReviewCounts || {}).sort())}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis unit="%" />
                    <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="Market Average"
                      stroke="#ED8936"
                      strokeWidth={3}
                      strokeDasharray="5 5"
                    />
                    {top5Growth.map((business, index) => (
                      <Line
                        key={business.name}
                        type="monotone"
                        dataKey={business.name.split(' ').slice(0, 2).join(' ')}
                        stroke={COLORS[index]}
                        strokeWidth={2}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </TabPanel>

            {/* Rankings Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box>
                  <RankingListComponent
                    title="Top Relative Growth Rankings"
                    data={top5Growth}
                    valueKey="relativeGrowth"
                    unit="%"
                    side="top"
                  />
                </Box>
                <Box>
                  <RankingListComponent
                    title="Lowest Relative Growth Rankings"
                    data={bottom5Growth}
                    valueKey="relativeGrowth"
                    unit="%"
                    side="bottom"
                  />
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <DataTable
                columns={columns}
                data={businessGrowthData}
                pagination
                paginationPerPage={10}
                sortable
                striped
                responsive
                highlightOnHover
                pointerOnHover
                onRowClicked={handleRowClick}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                theme="default"
                customStyles={{
                  rows: {
                    style: {
                      minHeight: '52px',
                      cursor: 'pointer',
                    },
                  },
                  headCells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      fontWeight: 'bold',
                    },
                  },
                  cells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    },
                  },
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Add the Modal */}
      <BusinessReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        business={selectedBusiness}
        yearData={yearData}
      />
    </Box>
  );
};

export default RelativeGrowthDashboard; 