import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider, Box, Spinner, Flex } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import WeekReviewParentComponentV2 from './components/WeekReviewParentComponentV2';
import YearReviewParentComponent from './components/YearReviewParentComponent';
import CompareWeekV3 from './components/CompareWeekV3';
import DashboardWrapper from './components/dashboards/DashboardWrapper';
import Login from './components/Login';

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9FZAwhdtUBMS7e2HLli92L59-9KFRIK0",
  authDomain: "sub40-411602.firebaseapp.com",
  projectId: "sub40-411602",
  storageBucket: "sub40-411602.appspot.com",
  messagingSenderId: "390182094470",
  appId: "1:390182094470:web:894433eb7c550d22423307"
};

// Initialize Firebase
initializeApp(firebaseConfig);

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Box minH="100vh" bg="gray.50">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Box>
                    <Navbar />
                    <Box 
                      as="main" 
                      ml={{ base: "72px", lg: "72px" }}
                      transition="margin-left 0.2s"
                      p={4}
                    >
                      <Routes>
                        <Route 
                          path="/dashboards/total-reviews" 
                          element={<DashboardWrapper type="total-reviews" />} 
                        />
                        <Route 
                          path="/dashboards/gross-growth" 
                          element={<DashboardWrapper type="gross-growth" />} 
                        />
                        <Route 
                          path="/dashboards/relative-growth" 
                          element={<DashboardWrapper type="relative-growth" />} 
                        />
                        <Route 
                          path="/week-in-review" 
                          element={<WeekReviewParentComponentV2 />} 
                        />
                        <Route 
                          path="/year-in-review" 
                          element={<YearReviewParentComponent />} 
                        />
                        <Route 
                          path="/compare-week" 
                          element={<CompareWeekV3 initialView="weeks" />} 
                        />
                        <Route 
                          path="/compare-year" 
                          element={<CompareWeekV3 initialView="years" />} 
                        />
                        <Route 
                          path="/" 
                          element={<DashboardWrapper type="total-reviews" />} 
                        />
                      </Routes>
                    </Box>
                  </Box>
                </PrivateRoute>
              }
            />
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;